


import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import ChatHelper from '@/_modules/chat/helpers/chat.helper';
import {TEvent} from '@/_types/event.type';

@Component
export default class SectionAboutEvent extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public get preparedAgenda(): string[] {
    if (this.event && this.event.agendas) {
      return this.event.agendas.split(/(\r\n|\r|\n)/).filter(x => x);
    }
    return [];
  }

  public get preparedEventDescription(): string {
    if (!this.event || !this.event.descr) {
      return '';
    }
    return this.linkifyUrls(this.event.descr);
  }

  public get isSectionVisible(): boolean {
    return this.preparedAgenda.length > 0
      || !!this.preparedEventDescription;
  }

  public linkifyUrls(input: string): string {
    return ChatHelper.createLinks(input);
  }
}
