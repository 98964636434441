


import {Vue, Component, Watch} from 'vue-property-decorator';
import {Action, Getter} from 'vuex-class';
import PromoSideBarLeft from '@/_modules/promo/components/side-bar-left/side-bar-left.vue';
import PromoSideBarRight from '@/_modules/promo/components/side-bar-right/side-bar-right.vue';
import EwCircularLoading from '@/_ui/ew-circular-loading/ew-circular-loading.vue';
import SimplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import {TEvent} from '@/_types/event.type';
import {TContact} from '@/_types/contact.type';
import {TEventSettings} from '@/_types/event-settings.type';
import SeoHelper from '@/_helpers/seo-helper';
import SectionHero from '@/_modules/event-page/section-hero/section-hero.vue';
import SectionApps from '@/_modules/event-page/section-apps/section-apps.vue';
import SectionAboutEvent from '@/_modules/event-page/section-about-event/section-about-event.vue';
import SectionMedia from '@/_modules/event-page/section-media/section-media.vue';
import SectionVenue from '@/_modules/event-page/section-venue/section-venue.vue';
import SectionVenueMap from '@/_modules/event-page/section-venue-map/section-venue-map.vue';
import SectionContacts from '@/_modules/event-page/section-contacts/section-contacts.vue';
import FlowParamAcceptor from '@/_modules/events/components/flow-param-acceptor/flow-param-acceptor.vue';

@Component({
  components: {
    PromoSideBarLeft,
    PromoSideBarRight,
    EwCircularLoading,
    SimplePopup,
    SectionHero,
    SectionApps,
    SectionAboutEvent,
    SectionVenue,
    SectionVenueMap,
    SectionContacts,
    SectionMedia,
    FlowParamAcceptor,
  }
})
export default class EventPageViewer extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('_eventStore/event') event: TEvent;
  @Getter('_eventStore/eventSettings') eventSettings: TEventSettings;
  @Getter('promoPageStore/contact') contact: TContact;
  @Getter('_eventStore/isPrivateEventDialogVisible') isPrivateEventDialogVisible: boolean;

  @Action('sideBarRightStore/close') closeSideBarRight: () => void;
  @Action('_eventStore/setPrivateEventDialogVisibility') setPrivateEventDialogVisibility: (isVisible: boolean) => void;

  public get eventId(): number {
    return this.$route.params.eventId ? parseInt(this.$route.params.eventId, 10) : null;
  }

  public get isEventPageReady(): boolean {
    return !!(this.event && this.event.id);
  }

  public get isFlowParamAcceptorNeeded(): boolean {
    const flowRaw = (this.$route.query && this.$route.query.flow) || null;
    const flowName = (flowRaw && !Array.isArray(flowRaw)) ? flowRaw as string : '';
    const isSaveEventFlow = flowName.toLowerCase().trim() === 'saveevent';

    return this.$route.name === 'event-info'
      && isSaveEventFlow;
  }

  @Watch('event.id', {immediate: true})
  private onEventChange(): void {
    this.manageSeoInfo();
  }

  public mounted(): void {
    this.closeSideBarRight();
    this.checkQuerySignIn();
  }

  public checkQuerySignIn(): void {
    if (this.$route.hash && this.$route.hash === '#auth') {
      if (!this.isAuthenticated) {
        this.$store.dispatch('authStore/setStandaloneAuthVisibility', true);
        return;
      }

      this.$router.push({
        name: 'promo-live',
      }).catch(() => { /* ignore */ });
    }
  }

  public manageSeoInfo(): void {
    if (!this.event) {
      SeoHelper.resetMetaTagsContent();
      return;
    }

    const event = this.event;
    const descriptionTemplateKey = event.descr ? 'metaTagTemplates.eventPage.description' : 'metaTagTemplates.eventPage.descriptionDefault';
    SeoHelper.addEventPageMetaTags(event, {
      title: this.$t('metaTagTemplates.eventPage.title') as string,
      description: this.$t(descriptionTemplateKey) as string,
    });
  }

}
