


import {Vue, Component, Watch} from 'vue-property-decorator';
import { Getter, Action } from 'vuex-class';
import simplePopup from '@/_modules/controls/components/simple-popup/simple-popup.vue';
import {TContact} from '@/_types/contact.type';
import _cloneDeep from 'lodash.clonedeep';
import eventApi from '@/_modules/events/api/event/event.api';
import {TUser} from '@/_types/user.type';
// @ts-ignore
import Statistics from '@/services/statistics.js';
import {TEvent} from '@/_types/event.type';

@Component({
  components: {
    simplePopup,
  }
})
export default class FlowParamAcceptor extends Vue {

  @Getter('authStore/isAuthenticated') isAuthenticated: boolean;
  @Getter('authStore/isStandaloneAuthVisible') isStandaloneAuthVisible: boolean;
  @Getter('authStore/userData') user: TUser;
  @Getter('promoPageStore/contact') myself: TContact;

  @Action('authStore/setStandaloneAuthVisibility') setStandaloneAuthVisibility: (isVisible: boolean) => void;
  @Action('authStore/setContactInfoInviterVisibility') setContactInfoInviterVisibility: (isVisible: boolean) => void;
  @Action('promoPageStore/getContact') getContact: (params: { eventId: number }) => Promise<void>;
  @Action('_eventStore/hideBecomingAContactConfirm') hideBecomingAContactConfirm: () => void;

  public isSaveEventFlowFinished: boolean = false;
  public isSaveEventFlowInProgress: boolean = false;

  public get flowName(): string {
    // Route.query.code can be an array. We will only accept it if it is a string.
    if (this.$route.query && this.$route.query.flow && !Array.isArray(this.$route.query.flow)) {
      return this.$route.query.flow as string;
    }
    return '';
  }

  public get isSaveEventFlow(): boolean {
    return this.flowName.toLowerCase().trim() === 'saveevent';
  }

  public get isFlowNameValid(): boolean {
    return this.isSaveEventFlow;
  }

  public get isFlowAcceptorIndicatorVisible(): boolean {
    return !this.isSaveEventFlowFinished && !this.isStandaloneAuthVisible;
  }

  public get eventId(): number {
    return (this.$route.params.eventId && parseInt(this.$route.params.eventId, 10)) || null;
  }

  @Watch('flowName', {immediate: true})
  public onFlowNameChange(): void {
    if (this.isSaveEventFlow) {
      this.attemptTheSaveEventFlow();
    }
  }

  @Watch('isAuthenticated')
  public async onIsAuthenticatedChange(newVal: boolean): Promise<void> {
    if (newVal) {
      await this.attemptTheSaveEventFlow();
    }
  }

  public async attemptTheSaveEventFlow(): Promise<void> {

    if (this.isSaveEventFlowInProgress) {
      return;
    }

    this.isSaveEventFlowInProgress = true;

    if (!this.isAuthenticated) {
      this.setStandaloneAuthVisibility(true);
      this.isSaveEventFlowInProgress = false;
      return;
    }

    this.setStandaloneAuthVisibility(false);
    this.setContactInfoInviterVisibility(false);
    this.hideBecomingAContactConfirm(); // TODO: remove when Hennadiy understands

    await this.getContact({ eventId: this.eventId });

    if (!this.myself || !this.myself.id) {
      await eventApi.patchIsGoing({ eventId: this.eventId, isGoing: true });
    }

    let tempEv: TEvent;
    try {
      tempEv = await eventApi.getEvent({eventId: this.eventId });
    } catch { /* ignore */ }

    const isAlreadyFavorite = (tempEv && tempEv.personal && tempEv.personal.is_favorite) || false;
    if (isAlreadyFavorite) {
      this.isSaveEventFlowInProgress = false;
      this.showSaveEventSuccessMessage();
      return;
    }

    let addFavError: any = null;
    await eventApi.addEventToFavorites({ eventId: this.eventId }).catch(e => { addFavError = e; });
    if (!addFavError) {
      await Statistics.addEventToFavorites({
        eventId: this.eventId,
        userId: (this.user && this.user.id) || 0,
        flowName: this.isFlowNameValid ? this.flowName : 'INVALID_FLOW_NAME',
        actionName: 'add_fav_event_from_widget',
      }, { to: null, from: null, next: null })
    }

    this.isSaveEventFlowInProgress = false;
    this.showSaveEventSuccessMessage();
  }

  public showSaveEventSuccessMessage(): void {
    this.isSaveEventFlowFinished = true;
  }

  public redirectAfterAttempt(): void {
    const newQuery: {[key: string]: string | (string | null)[]} = _cloneDeep(this.$route.query);
    delete newQuery.flow;

    window.location.replace(this.$router.resolve({
      name: this.getRouteNameForRedirection(),
      params: _cloneDeep(this.$route.params),
      query: newQuery,
      hash: window.location.hash
    }).href);
  }

  public getRouteNameForRedirection(): string {
    return 'event-info';
  }

  public onSaveEventFlowFinishedPopupClose(): void {
    this.isSaveEventFlowFinished = false;
    this.doDelayedRedirect();
  }

  // Timeout to give running time to fadeout transition
  public doDelayedRedirect(): void {
    window.setTimeout(() => {
      this.redirectAfterAttempt();
    }, 400);
  }

}
