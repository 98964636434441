


import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';

@Component
export default class SectionVenue extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public get eventAddress(): string {
    return (this.event && this.event.address) || '';
  }

  public get eventCountryName(): string {
    return (this.event && this.event.country && this.event.country.name) || '';
  }

  public get eventCityName(): string {
    return (this.event && this.event.city && this.event.city.name) || '';
  }

  public get addressTextPrepared(): string {
    return [
      this.eventAddress,
      this.eventCityName,
      this.eventCountryName,
    ]
      .filter(x => x)
      .join(', ');
  }

  public get isSectionVisible(): boolean {
    return !this.addressTextPrepared;
  }

}
