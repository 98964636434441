import { TEvent } from '@/_types/event.type';
import i18n from '@/i18n';

const VUE_APP_NAME = process.env.VUE_APP_NAME;

type TEventPageMetaTagTemplates = {
  title: string;
  description: string;
}

export default class SeoHelper {

  public static get headElement(): Element {
    const heads: HTMLCollection = document.getElementsByTagName('head');
    return heads && heads.length ? heads[0] : null;
  }

  public static get titleElement(): Element {
    const titles: HTMLCollection = document.getElementsByTagName('title');
    return titles && titles.length ? titles[0] : null;
  }

  public static get metaDescriptionElement(): Element {
    return document.querySelector('meta[name="description"]') || this.createMetaDescriptionElement();
  }

  public static createMetaDescriptionElement(): Element {
    const metaDescrEl = document.createElement('meta');
    metaDescrEl.setAttribute('name', 'description');
    metaDescrEl.setAttribute('content', '');
    if (this.headElement && this.titleElement) {
      if (this.titleElement.nextElementSibling) {
        this.headElement.insertBefore(metaDescrEl, this.titleElement.nextElementSibling);
      } else {
        this.headElement.appendChild(metaDescrEl);
      }
    }
    return metaDescrEl;
  }

  public static resetMetaTagsContent(): void {
    this.setDefaultTitleTag();
    this.setEmptyMetaDescription();
  }

  public static setDefaultTitleTag(): void {
    document.title = VUE_APP_NAME;
  }

  public static setEmptyMetaDescription(): void {
    this.metaDescriptionElement.setAttribute('content', '');
  }

  public static addEventPageMetaTags(event: TEvent, templates: TEventPageMetaTagTemplates): void {
    this.setEventPageTitle(event, templates.title);
    this.setEventPageMetaDescription(event, templates.description);
  }

  public static setEventPageTitle(event: TEvent, template: string): void {
    let result = '';
    if (template && event.title) {
      result = template
        .replace(/\[EVENT_TITLE]/g, event.title)
        .replace(/\[EVENT_TYPE]/g, this.getEventTypeName(event))
        .replace(/\[SITE_NAME]/g, VUE_APP_NAME);
    }
    if (result !== '') {
      document.title = this.prepareHtml(result);
    } else {
      this.setDefaultTitleTag();
    }
  }

  public static setEventPageMetaDescription(event: TEvent, template: string): void {
    let result = '';
    if (template && event.title) {
      result = template
        .replace(/\[EVENT_TITLE]/g, event.title)
        .replace(/\[EVENT_TYPE]/g, this.getEventTypeName(event))
        .replace('[EVENT_DESCRIPTION]', event.descr)
        .replace(/\[SITE_NAME]/g, VUE_APP_NAME);
      result = this.prepareHtmlAttributeContent(result.substr(0, 255));
    }
    this.metaDescriptionElement.setAttribute('content', result);
  }

  public static getEventTypeName(event: TEvent): string {
    return event.event_type && event.event_type.name ? event.event_type.name : i18n.tc('metaTagTemplates.common.defaultEventType');
  }

  public static prepareHtmlAttributeContent(input: string): string {
    return input
      .replace('&', '&amp;')
      .replace('"', '&quot;');
  }

  public static prepareHtml(input: string): string {
    return input
      .replace('<', '&lt;');
  }
}
