


import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';

@Component
export default class SectionContacts extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public get isSectionContactsVisible(): boolean {
    return !!(
      this.eventOrganizerName
      || this.eventOrganizerEmail
      || this.eventOrganizerPhone
      || this.eventWebsiteUrl
    );
  }

  public get eventOrganizerName(): string {
    return (this.event && this.event.org_name) || '';
  }

  public get eventOrganizerPhone(): string {
    return (this.event && this.event.org_phone) || '';
  }

  public get eventOrganizerEmail(): string {
    return (this.event && this.event.org_email) || '';
  }

  public get eventWebsiteUrl(): string {
    return (this.event && this.event.url) || '';
  }
}
