


import {Vue, Component} from 'vue-property-decorator';
import QrcodeVue from 'qrcode.vue';

const ENVIRONMENT_NAME = process.env.VUE_APP_ENV;
const ANDROID_APK_URL_CN = 'https://app.eventswallet.cn/downloads/android/eventswallet.apk';

@Component({
  components: {
    QrcodeVue,
  }
})
export default class SectionApps extends Vue {

  public get isCN(): boolean {
    return (ENVIRONMENT_NAME === 'cn');
  }

  public get androidApkUrl(): string {
    return ANDROID_APK_URL_CN;
  }

  public get iosAppUrl(): string {
    return 'https://apps.apple.com/ua/app/events-wallet/id1506448963?l=' + this.$i18n.locale;
  }

  public get iosAppQrCodeContents(): string {
    if (this.isCN) {
      return this.iosAppUrl;
    }
    const host = ENVIRONMENT_NAME === 'development' ? window.location.host : 'app.eventswallet.com';
    return [
      'https://app-link.eventswallet.com/?link=',
      'https://' + host + '/' + this.$i18n.locale + '/events/',
      this.$route.params.eventId,
      '&apn=com.biletum&isi=1506448963&ibi=com.biletum.edsson.Biletum',
    ].join('');
  }

  public get sectionTitle(): string {
    return this.getTranslationIfExisting('metaTagTemplates.eventPage.mobileStoreInfo.title', 'Events mobile app');
  }

  public get sectionDescription(): string {
    return this.getTranslationIfExisting('metaTagTemplates.eventPage.mobileStoreInfo.description', '');
  }

  private getTranslationIfExisting(key: string, fallbackValue: string = ''): string {
    const isExisting = this.$te(key);
    if (!isExisting) {
      return fallbackValue;
    }
    return this.$t(key) as string;
  }

}
