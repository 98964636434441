


import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import LocationPicker from '@/views/components/locationPicker/locationPicker.vue';
import baiduMap from '@/views/components/locationPicker/baiduMap.vue';
import {TEvent} from '@/_types/event.type';

@Component({
  components: {
    LocationPicker,
    baiduMap,
  }
})
export default class SectionVenueMap extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public get eventVenueTitle(): string {
    return ''; // N.B.: we dont seem to have venue title in the API. TODO: check
  }

  public get eventAddress(): string {
    return (this.event && this.event.address) || '';
  }

  public get eventCountryName(): string {
    return (this.event && this.event.country && this.event.country.name) || '';
  }

  public get eventCityName(): string {
    return (this.event && this.event.city && this.event.city.name) || '';
  }

  public get mapProvider(): string { // 'google' | 'baidu'
    let mapProvider = process.env.VUE_APP_MAP_PROVIDER;
    if (this.$route.query && this.$route.query.mp && this.$route.query.mp === 'baidu') {
      mapProvider = 'baidu';
    }
    return mapProvider;
  }

  public get locationPickerAddress(): string {
    return [
      this.eventAddress,
      this.eventCountryName,
      this.eventCityName,
    ].filter(x => x).join(', ');
  }

}
