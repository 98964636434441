


import {Vue, Component} from 'vue-property-decorator';
import {Getter} from 'vuex-class';
import {TEvent} from '@/_types/event.type';

@Component
export default class SectionMedia extends Vue {

  @Getter('_eventStore/event') event: TEvent;

  public get eventPhotos(): string[] {
    return (this.event && this.event.photos) || [];
  }
}
