import { render, staticRenderFns } from "./section-venue.html?vue&type=template&id=181655f2&scoped=true&"
import script from "./section-venue.vue?vue&type=script&lang=ts&"
export * from "./section-venue.vue?vue&type=script&lang=ts&"
import style0 from "./section-venue.scss?vue&type=style&index=0&id=181655f2&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "181655f2",
  null
  
)

export default component.exports