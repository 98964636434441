export default class TranslitHelper {

  /* TODO: finish it
   * https://en.wikipedia.org/wiki/List_of_Cyrillic_letters
   */
  public getTransliterated(text: string): string {
    text = this.getPreparedForTransliteration(text).toLowerCase();
    const cyr = 'аӑӓәӛӕäàãбвгґѓғӷҕдђеёѐёӗҽҿèêжӂҗӝзҙӟӡѕиѝӥӣӏйҋјкқҟҡӄҝќлӆљмӎнӊңӈҥњоӧөӫҩöпԥрҏсҫтҭћуўӳӱӯүұüфхҳһцҵчӵҷӌҹџшщьыӹъҍэюяіїє'.split('');
    const cyrToLatin = [
      'a', 'a', 'a', 'a', 'e', 'a', 'a', 'a', 'a',
      'b',
      'v',
      'g', 'g', 'g', 'r', 'g', 'g',
      'd', 'dj',
      'e', 'e', 'e', 'e', 'e', 'ts', 'ts', 'e', 'e',
      'zh', 'zh', 'zh', 'zh',
      'z', 'z', 'z', 'z', 'dz',
      'i', 'i', 'i', 'i', 'u',
      'y', 'y', 'y',
      'k', 'k', 'k', 'k', 'k', 'k', 'kj',
      'l', 'l', 'l',
      'm', 'ӎ',
      'n', 'n', 'n', 'n', 'n', 'n',
      'o', 'o', 'o', 'o', 'o', 'o',
      'p', 'p',
      'r', 'r',
      's', 'ch',
      't', 't', 't',
      'u', 'w', 'u', 'u', 'uu', 'ue', 'u', 'u',
      'f',
      'h', 'h', 'h',
      'c', 'c',
      'ch', 'ch', 'ch', 'ch', 'ch', 'dch',
      'sh',
      'shch',
      '',
      'y', 'y',
      '', 'y',
      'e',
      'u',
      'ya',
      'i',
      'yi',
      'ye'
    ];

    let result = '';
    for (let i = 0; i < text.length; i++) {
      const foundIndex = cyr.findIndex(cyrLetter => text[i] === cyrLetter);
      if (foundIndex >= 0) {
        result += cyrToLatin[foundIndex];
        continue;
      }
      result += text[i];
    }

    return result;
  }

  public getPreparedForTransliteration(text: string): string {
    const toRemove = /[!@„“”‘′«»<>'"’]/g;
    const toUnderscore = /[_—.,|/\\:!?#$%^&*°£₽≈()©®™€§ѣ✓ѵѳ[\]{}=→←↑\s+-]/gi;
    const multipleUnderscores = /_{2,}/g;
    return text
      .trim()
      .toLowerCase()
      .replace(toRemove, '')
      .replace(toUnderscore, '_')
      .replace(/\s+/g, '_')
      .replace(multipleUnderscores, '_');
  }
}
